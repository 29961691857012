var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "添加分组",
            center: "",
            visible: _vm.showuser,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showuser = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: {
                            width: "100%",
                            "margin-bottom": "20px",
                          },
                          attrs: {
                            stripe: "",
                            data: _vm.department,
                            "row-key": "id",
                            height: "400",
                            "default-expand-all": "",
                            "tree-props": {
                              children: "children",
                              hasChildren: "hasChildren",
                            },
                          },
                          on: { "row-click": _vm.handlerrow },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "text", label: "部门" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "table",
                          attrs: {
                            stripe: "",
                            data: _vm.userlist,
                            height: "400",
                            "row-key": _vm.getRowKey,
                          },
                          on: { "selection-change": _vm.setSelectRows },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "selection",
                              "reserve-selection": true,
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "员工", prop: "user_name" },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "考勤分组", prop: "attend_name" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.handleradduser },
                        },
                        [_vm._v(" 添 加 ")]
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("i", {
                      staticClass: "el-icon-right fonts",
                      on: { click: _vm.handleradduser },
                    }),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-table",
                        { attrs: { stripe: "", data: _vm.userinfo } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "部门",
                              prop: "depart_name",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "姓名",
                              prop: "user_name",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "电话",
                              prop: "mobile",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerdelet(row, $index)
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("提示:新添加的员工，考勤规则次日生效"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showuser = !_vm.showuser
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }