<!--
 * @Author: your name
 * @Date: 2021-03-24 16:31:10
 * @LastEditTime: 2021-04-28 10:03:46
 * @LastEditors: Please set LastEditors
 * @Description: 添加分组
 * @FilePath: \sd-vue-admin\src\views\setting\featuresManagement\attendance\components\AddUser.vue
-->
<!--  -->
<template>
  <div class="orderTest-container">
    <el-dialog
      :modal="false"
      title="添加分组"
      center
      :visible.sync="showuser"
      width="80%"
    >
      <div>
        <el-row :gutter="30">
          <el-col :span="6">
            <el-table
              stripe
              :data="department"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              height="400"
              default-expand-all
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @row-click="handlerrow"
            >
              <el-table-column prop="text" label="部门"></el-table-column>
            </el-table>
          </el-col>
          <el-col :span="6">
            <el-table
              ref="table"
              stripe
              :data="userlist"
              height="400"
              :row-key="getRowKey"
              @selection-change="setSelectRows"
            >
              <el-table-column
                type="selection"
                :reserve-selection="true"
              ></el-table-column>
              <el-table-column label="员工" prop="user_name"></el-table-column>
              <el-table-column
                label="考勤分组"
                prop="attend_name"
              ></el-table-column>
            </el-table>
            <el-button
              style="margin-top: 20px"
              type="primary"
              @click="handleradduser"
            >
              添 加
            </el-button>
          </el-col>
          <el-col :span="2">
            <i class="el-icon-right fonts" @click="handleradduser"></i>
          </el-col>
          <el-col :span="10">
            <el-table stripe :data="userinfo">
              <el-table-column
                label="部门"
                prop="depart_name"
                align="center"
              ></el-table-column>
              <el-table-column
                label="姓名"
                prop="user_name"
                align="center"
              ></el-table-column>
              <el-table-column
                label="电话"
                prop="mobile"
                align="center"
              ></el-table-column>
              <el-table-column label="操作" align="center">
                <template #default="{ row, $index }">
                  <el-button type="text" @click="handlerdelet(row, $index)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <span style="color: red">提示:新添加的员工，考勤规则次日生效</span>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlersave">确 定</el-button>
        <el-button @click="showuser = !showuser">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        showuser: false,
        department: [],
        userlist: [],
        userinfo: [],
        url: {
          userinfo: '/setAdmin/attendance/rel-list',
          userlist: 'baseAdmin/common/user-list',
          department: '/baseAdmin/depart/index',
          save: 'setAdmin/attendance/set-attend-role',
        },
        selectedRow: [],
        infoid: '',
      }
    },

    computed: {},
    watch: {
      showuser(val) {
        if (!val) {
          this.userlist = []
          this.selectedRow = []
          this.userinfo = []
        }
      },
      userlist(val) {
        // this.$refs.table.clearSelection()
      },
    },
    created() {},
    mounted() {
      this.handlerdepartment()
    },
    methods: {
      handlreinfo(row) {
        // console.log(row, '')
        this.infoid = row.id
        postAction(this.url.userinfo, { id: row.id, pageSize: -1 })
          .then((res) => {
            console.log(res, '')
            this.userinfo = res.data
            // this.userlist = res.data
            this.selectedRow = res.data
            console.log(this.userinfo, this.userlist, this.selectedRow, '')
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlersave() {
        var data = new Array()
        this.userinfo.forEach((list) => {
          data.push(list.user_id)
        })
        postAction(this.url.save, {
          id: this.infoid,
          user_data: data.join(','),
        })
          .then((res) => {
            console.log(res, '')
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.showuser = false
            this.$emit('getlist')
            this.$refs.table.clearSelection()
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerdelet(row, index) {
        console.log(row, this.$refs.tablesss, '')
        this.$refs.table.toggleRowSelection(row, false)
        this.userinfo.splice(index, 1)
      },
      handleradduser() {
        this.userinfo.push(...this.selectedRow)
        let obj = {}

        let peon = this.userinfo.reduce((cur, next) => {
          obj[next.user_id] ? '' : (obj[next.user_id] = true && cur.push(next))
          return cur
        }, [])
        console.log(peon, '')
        this.userinfo = peon
        // this.userinfo=[...new Set(this.userinfo)]
      },
      getRowKey(row) {
        return row.user_id
      },
      setSelectRows(val) {
        console.log(val)
        this.selectedRow = val
      },
      handlerrow(row) {
        console.log(row)
        postAction(this.url.userlist, { depart_id: row.id })
          .then((res) => {
            console.log(res, '人员')
            this.userlist = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },

      handlerdepartment() {
        postAction(this.url.department, { pageSize: -1 })
          .then((res) => {
            console.log(res, '部门')
            this.department = res.data.tree
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .fonts {
    margin-top: 200px;
    font-size: 60px;
    font-weight: 600;
  }
</style>
